import React, { Fragment } from 'react'
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
const BackgroundImage = dynamic(() => import('./BackgroundImage/BackgroundImage'))
const Img = dynamic(() => import('./Img/Img'))
import { ImageConstants } from '../../adapters/helpers/Constants'

export default function Image(props) {

    const { children = null, image,altText, scale, customStyles = null,noAltText, text, elemId = null, ariaHidden = false, imageFormat, imageQuality} = props;

    if (image) {
        const assetId = image?.fields?.assetId;
        const contentImageId = image?.fields?.asset?.fields?.file?.url;
        const alt = image?.fields?.alternateText;
        const isPreload = image?.fields?.isPreload;
        const isBannerImage = image?.fields?.isBannerImage;
        const isProductPage = image?.fields?.isProductPage;
        const rendition = image?.fields?.imageRendition;
        const aspectRatio = rendition?.fields?.aspectRatio;
        const transformations = rendition?.fields?.transformations;
        let customClassName = image?.fields?.classNames;
        const format = image?.fields?.forcedFormat || 'auto';
        const alignmentClassName = props.className;
        const resetImage = props?.resetImage;
        const product= ImageConstants?.product;

        if (props.zoneClassName) {
            if (!customClassName) {
                customClassName = '';
            }

            customClassName += props.zoneClassName;
        }

        return (children || props.forceBackground ?
                <BackgroundImage
                    {...props}
                    imageFormat={imageFormat}
                    imageQuality={imageQuality}
                    imageId={assetId}
                    contentImageId={contentImageId}
                    alt={(alt ? alt : noAltText ? '' : 'product')}
                    isPreload={isPreload}
                    isBannerImage={isBannerImage}
                    widthToHeightRatio={aspectRatio}
                    transformations={transformations}
                    customClassName={customClassName}
                    backgroundPosition={props.backgroundPosition}
                    backgroundOffsetTop={props.backgroundOffsetTop}
                    alignmentClassName={alignmentClassName}
                    format={format}
                    assetX={props.assetX}
                    assetY={props.assetY}
                    customStyles={customStyles}
                    hasAssetParallax={props.hasAssetParallax}
                    ariaHidden={ariaHidden}
                    preloadImage={props.preloadImage}
                    desktopBackgroundImage={props.desktopBackgroundImage}    
                    mobileBackgroundImage={props.mobileBackgroundImage}          
                >
                    {children}
                </BackgroundImage>
                :
                <Img
                    {...props}
                    imageFormat={imageFormat}
                    imageQuality={imageQuality}
                    scale={scale}
                    imageId={assetId}
                    contentImageId={contentImageId}
                    isPreload={isPreload}
                    isBannerImage={isBannerImage}
                    isProductPage={isProductPage}
                    elemId={elemId}
                    alt={altText ? altText : alt !== altText && ( !noAltText ) ? `${alt} ${text ? text: ''}`:'' }
                    widthToHeightRatio={aspectRatio}
                    transformations={transformations}
                    offsetTop={props.offsetTop}
                    offsetRight={props.offsetRight}
                    offsetBottom={props.offsetBottom}
                    offsetLeft={props.offsetLeft}
                    customClassName={customClassName}
                    alignmentClassName={alignmentClassName}
                    format={format}
                    resetImage={resetImage}
                    ariaHidden={ariaHidden}
                    imageClassName={props.imageClassName}
                />
        );
    } else {
        return (
            <Fragment>
                {children}
            </Fragment>
        )
    }
}

Image.propTypes = {
    offsetTop: PropTypes.string,
    offsetRight: PropTypes.string,
    offsetLeft: PropTypes.string,
    offsetBottom: PropTypes.string,
    classname: PropTypes.string,
    zoneClassName: PropTypes.string,
    children: PropTypes.node,
    image: PropTypes.any,
    ariaHidden: PropTypes.bool,
    isPreload:PropTypes.bool,
    isBannerImage: PropTypes.bool
};
